/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-07T16:08:85+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import React, {Component} from 'react';

export class privacy extends Component {
  render() {
    return (
      <div css={{padding: 20, lineHeight: 1.1}}>
        <p>
          Našou hlavnou prioritou je bezpečnosť a ochrana osobných údajov
          dotknutých osôb. <strong>ReactFISH, s.r.o.</strong> týmto v súlade so
          zákonom č. 122/2013 Z. z. o ochrane osobných údajov a o zmene a
          doplnení niektorých zákonov v znení neskorších predpisov oznamuje
          dotknutým osobám nasledovné informácie:
        </p>

        <p>
          <u>Osobné údaje dotknutých osôb sú spracúvané prevádzkovateľom:</u>
        </p>

        <dl>
          <dt>Obchodné meno:</dt>{' '}
          <dd>
            <strong>ReactFISH, s.r.o.</strong>
          </dd>
        </dl>
        <dl>
          <dt>Sídlo:</dt> <dd>Galvaniho 7/D, 821 04 Bratislava-Ružinov</dd>
        </dl>
        <dl>
          <dt>IČO:</dt> <dd>51 977 532</dd>
        </dl>

        <p>
          <u>Účel spracúvania osobných údajov:</u>
        </p>

        <p>
          Osobné údaje sú spracúvané v súlade s účelom podľa konkrétneho
          informačného systému osobných údajov spoločnosti ReactFISH, s.r.o.
          Osobné údaje dotknutých osôb sú spracúvané v nasledovných informačných
          systémoch:
          <ul>
            <li>
              IS Personalistika, účelom ktorého je spracúvanie údajov o
              zamestnancoch spoločnosti a o poskytovateľoch programátorských
              služieb prevádzkovateľa,
            </li>
            <li>
              IS Klienti, účelom ktorého je spracúvanie osobných údajov
              zákazníkov, klientov a ich zástupcov v rámci predzmluvných a
              zmluvných vzťahov s prevádzkovateľom, ako aj osobné údaje
              dotknutých osôb v databázach klientov;
            </li>
            <li>
              IS Obchodní partneri, účelom ktorého je spracúvanie osobných
              údajov spojených s komunikáciou s kontaktnými osobami našich
              obchodných partnerov,
            </li>
          </ul>
        </p>

        <p>
          <u>Rozsah spracúvaných osobných údajov:</u>
        </p>

        <p>
          Prevádzkovateľ spracúva všetky osobné údaje, ktoré sú mu poskytnuté
          dotknutou osobou na splnenie účelu konkrétneho informačného systému.
          Môže ísť o osobné údaje poskytnuté písomne, alebo ústne tak v
          manuálnej ako aj automatizovanej podobe. Ide najmä o identifikačné a
          kontaktné údaje dotknutej osoby a ďalšie údaje podľa príslušného
          informačného systému a účelu spracúvania osobných údajov.
          Prevádzkovateľ spracúva osobné údaje len v nevyhnutnom rozsahu a po
          zániku účelu spracúvania osobných údajov, osobné údaje likviduje.
        </p>

        <p>
          <u>
            Doplňujúce informácie na zaručenie práv a právom chránených záujmov
            dotknutých osôb:
          </u>
        </p>

        <p>
          Právnym základom spracúvania osobných údajov sú najmä príslušné právne
          predpisy, napr. zákon č. 311/2001 Z. z. Zákonník práce v znení
          neskorších predpisov, predzmluvné a zmluvné vzťahy prevádzkovateľa s
          dotknutou osobou podľa § 10 ods. 3 písm. b) zákona č. 122/2013 Z. z. o
          ochrane osobných údajov a o zmene a doplnení niektorých zákonov v
          znení neskorších predpisov. V prípade, kedy právny základ spracúvania
          osobných údajov nevyplýva z konkrétneho právneho predpisu alebo zmluvy
          s dotknutou osobou, spracúva prevádzkovateľ osobné údaje dotknutej
          osoby na základe súhlasu tejto osoby so spracúvaním jej osobných
          údajov.
        </p>

        <p>
          Dotknutá osoba má nárok, aby oprávnená osoba prevádzkovateľa, ktorá
          spracúva osobné údaje konkrétnej dotknutej osoby preukázala tejto
          dotknutej osobe svoju totožnosť. Dotknuté osoby majú možnosť sa
          informovať či poskytujú údaje do konkrétneho informačného systému
          prevádzkovateľa dobrovoľne, alebo či ide o povinnosť, ktorá im vyplýva
          zo zákona. Rovnako majú dotknuté osoby možnosť informovať sa, ktorým
          tretím stranám, alebo príjemcom môžu byť ich osobné údaje poskytnuté
          alebo sprístupnené. Prevádzkovateľ neuskutočňuje prenos spracúvaných
          osobných údajov do tretích krajín.
        </p>

        <p>
          Dotknutá osoba má právo na základe písomnej žiadosti od
          prevádzkovateľa vyžadovať: potvrdenie, či sú alebo nie sú osobné údaje
          o nej spracúvané; informácie o spracúvaní osobných údajov v
          informačnom systéme; presné informácie o zdroji, z ktorého získal jej
          osobné údaje na spracúvanie; zoznam jej osobných údajov, ktoré sú
          predmetom spracúvania; opravu alebo likvidáciu svojich nesprávnych,
          neúplných alebo neaktuálnych osobných údajov, ktoré sú predmetom
          spracúvania; likvidáciu jej osobných údajov, ktorých účel spracúvania
          sa skončil; blokovanie jej osobných údajov z dôvodu odvolania súhlasu
          pred uplynutím času jeho platnosti.
        </p>

        <p>
          Ak dotknutá osoba uplatní svoje právo písomne a z obsahu jej žiadosti
          vyplýva, že uplatňuje svoje právo, žiadosť sa považuje za podanú podľa
          platnej právnej úpravy. Žiadosť podanú elektronickou poštou alebo
          faxom musí dotknutá osoba doručiť písomne najneskôr do troch dní odo
          dňa jej odoslania.
        </p>
      </div>
    );
  }
}

export default privacy;
